import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Pagination = ({ pages, refresh, onChange, contentRef }) => {
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    onChange(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCurrentPage(1)
    onChange(1)
    contentRef && contentRef.scrollIntoView({ behavior: "smooth" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  let beforeDot = 0
  let afterDot = 0
  return (
    <PaginationWrap>
      <PrevNext
        onClick={() => {
          const cp = currentPage - 1
          setCurrentPage(cp)
          onChange(cp)
        }}
        disabled={currentPage <= 1}
      >
        <span>&#10094;</span> Prev
      </PrevNext>
      {Array.from({ length: pages }).map((_, i) => {
        if (i + 1 > 1 && i < currentPage - 2) {
          beforeDot = beforeDot + 1
          if (beforeDot === 1) {
            return <span key={i}>...</span>
          } else {
            return null;
          }
        } else if (i + 1 > currentPage + 1 && i + 1 < pages) {
          afterDot = afterDot + 1
          if (afterDot === 1) {
            return <span key={i}>...</span>
          } else {
            return null;
          }
        } else {
          return (
            <PageNum
              key={i}
              className={currentPage === i + 1 ? "active" : ""}
              onClick={() => {
                setCurrentPage(i + 1)
                onChange(i + 1)
              }}
            >
              {i + 1}
            </PageNum>
          )
        }
      })}
      <PrevNext
        onClick={() => {
          const cp = currentPage + 1
          setCurrentPage(cp)
          onChange(cp)
        }}
        disabled={currentPage >= pages}
      >
        Next <span>&#10095;</span>
      </PrevNext>
    </PaginationWrap>
  )
}

export default Pagination

export const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    gap: 5px;
  }
`

export const PageNum = styled.p`
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  color: black;
  margin-bottom: 0;

  &.active {
    color: white;
    background: blue;
  }
  &:hover {
    cursor: pointer;
  }
`

export const PrevNext = styled.button`
  border: 0;
  padding: 5px 10px;
  display: flex;
  color: black;

  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 12px;
    margin: 2px 5px 0;
  }

  @media (max-width: 600px) {
    padding: 5px 0;
  }

  &:disabled {
    color: gray;
    span {
      color: gray;
    }
  }
`
