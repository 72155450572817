import React, { Fragment, useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import {
  SectionPage,
  BreadCrumb,
  MainPanel,
  LeftPanel,
  RightPanel,
  Container,
  SectionPageTitle
} from "../components/Section"
import InfographicList from "../components/InfographicList"
import {
  SidebarSticky,
  SidebarForm,
  SidebarFormHeader,
  SidebarFormTitle,
  SidebarFormBody,
  SidebarCard,
  SidebarCardHeader,
  SidebarCardTitle,
  SidebarCardBody,
} from "../components/Sidebar"
import {
  MediaList,
  Media,
  MediaThumb,
  MediaBody,
  ListBadge,
  ListItemBadge,
  Badge,
} from "../components/ListGroup"
import QuickContactForm from "../components/QuickContactForm"
import Img from "gatsby-image"
import Pagination from "../components/Pagination"

const NoContent = styled.h3`
  text-align: center;
`

const InfographicPage = ({ data, location, formName }) => {
  const {
    allContentfulInfographic: { edges: totalInfographics },
    allContentfulInfographicCategory: { edges: categories },
  } = data
  const [isCurrent, setIsCurrent] = useState(
    location?.state?.category || "All Infographic"
  )
  const [infographicsData, setInfographicsData] = useState([])
  const [showInfographics, setShowInfographics] = useState([])
  const pageSize = 4
  const recentPosts = totalInfographics.slice(0, 3)

  useEffect(() => {
    if (isCurrent === "All Infographic") {
      setInfographicsData(totalInfographics)
    } else {
      let infographics = []
      totalInfographics.forEach(item => {
        if (item.node.category.name === isCurrent) {
          infographics.push(item)
        }
      })
      setInfographicsData(infographics)
    }
    document
      .getElementById("list-content")
      .scrollIntoView({ behavior: "smooth" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrent])

  useEffect(() => {
    if (infographicsData.length <= pageSize) {
      setShowInfographics(infographicsData)
    }
  }, [infographicsData])

  const infographicCount = categoryName => {
    let count = 0
    totalInfographics.forEach(item => {
      if (item.node.category.name === categoryName) {
        count++
      }
    })
    return count
  }

  const refreshPage = pageNo => {
    const startIndex = (pageNo - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize, infographicsData.length)
    const pageData = infographicsData.slice(startIndex, endIndex)
    setShowInfographics([...pageData])
    document
      .getElementById("list-content")
      .scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout location={location}>
      <SEO title="Infographic" description="Infographic" />
      <SectionPage
          ept="160px"
          epb="80px"
          xpt="140px"
          xpb="40px"
          pt="100px"
          pb="30px"
          bg="#fff"
          id="list-content"
        >
        <Container>
          <BreadCrumb className="static"><Link to="/">Home</Link> / <span>Infographics</span></BreadCrumb>
          <SectionPageTitle>Probuilt Infographic</SectionPageTitle>
          <MainPanel>
            <LeftPanel>
              {infographicsData.length > 0 ? (
                <Fragment>
                  <InfographicList data={showInfographics} />
                  {infographicsData.length > pageSize && (
                    <Pagination
                      pages={Math.ceil(infographicsData.length / pageSize)}
                      onChange={refreshPage}
                      refresh={isCurrent}
                      contentRef={document.getElementById("list-content")}
                    />
                  )}
                </Fragment>
              ) : (
                <NoContent>No Related Inforgraphic Content</NoContent>
              )}
            </LeftPanel>
            <RightPanel>
              <SidebarSticky className="sidebar isSticky">
                <SidebarCard>
                  <SidebarCardHeader>
                    <SidebarCardTitle>Recent Posts</SidebarCardTitle>
                  </SidebarCardHeader>
                  <SidebarCardBody>
                    <MediaList className="media-list">
                      {recentPosts.map((post, i) => {
                        const temp = post.node.title
                          .replace(/[^\w\s]+/gi, " ")
                          .replace(/  +/g, " ")
                        const titleUrl = temp
                          .split(" ")
                          .join("-")
                          .toLowerCase()
                        const url =
                          titleUrl.slice(-1) === "-"
                            ? titleUrl.slice(0, -1)
                            : titleUrl
                        return (
                          <Media className="media" key={i}>
                            <MediaThumb className="media-thumb">
                              <Img
                                fluid={post.node.thumbnail.fluid}
                                alt={`thumbnail-${post.node.title}`}
                              />
                            </MediaThumb>
                            <MediaBody className="media-body">
                              <p>
                                <Link to={`/infographics/${url}/`}>
                                  {post.node.title}
                                </Link>
                              </p>
                              <small>{post.node.createdAt}</small>
                            </MediaBody>
                          </Media>
                        )
                      })}
                    </MediaList>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarCard>
                  <SidebarCardHeader>
                    <SidebarCardTitle>Infographic Categories</SidebarCardTitle>
                  </SidebarCardHeader>
                  <SidebarCardBody>
                    <ListBadge className="list-badge">
                      <ListItemBadge className="list-item">
                        <Badge
                          bg={
                            isCurrent === "All Infographic"
                              ? "#ED602B"
                              : "white"
                          }
                          color={
                            isCurrent === "All Infographic"
                              ? "white"
                              : "#140F0F"
                          }
                          onClick={() => setIsCurrent("All Infographic")}
                        >
                          {" "}
                          All Infographic{" "}
                          <span>{totalInfographics.length}</span>
                        </Badge>
                      </ListItemBadge>

                      {categories.map((category, i) => (
                        <ListItemBadge className="list-item" key={i}>
                          <Badge
                            bg={
                              isCurrent === category.node.name
                                ? "#ED602B"
                                : "white"
                            }
                            color={
                              isCurrent === category.node.name
                                ? "white"
                                : "#140F0F"
                            }
                            onClick={() => setIsCurrent(category.node.name)}
                          >
                            <span className="tag">{category.node.name}</span>
                            <span className="count">
                              {infographicCount(category.node.name)}
                            </span>
                          </Badge>
                        </ListItemBadge>
                      ))}
                    </ListBadge>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarForm>
                  <SidebarFormHeader>
                    <SidebarFormTitle>GET IN TOUCH</SidebarFormTitle>
                  </SidebarFormHeader>
                  <SidebarFormBody>
                    <QuickContactForm
                      location={location}
                      formName={formName ? formName : "Quick Infographic Form"}
                    />
                  </SidebarFormBody>
                </SidebarForm>
              </SidebarSticky>
            </RightPanel>
          </MainPanel>
        </Container>
      </SectionPage>
      <CallToAction />
    </Layout>
  )
}

export default InfographicPage

export const pageQuery = graphql`
  query InfographicPageQuery {
    allContentfulInfographic {
      edges {
        node {
          title
          thumbnail {
            fluid(maxWidth: 2500) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          description {
            description
          }
          category {
            name
          }
          createdAt(formatString: "D MMM YYYY")
        }
      }
    }
    allContentfulInfographicCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`
